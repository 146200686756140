import React from 'react';

function KonkWheel() {
  return (
    <div style={{ width: '100%', height: '100vh' }}>
      <iframe
        src="/dapps/konk-wheel/index.html"
        style={{ width: '100%', height: '100%', border: 'none' }}
        title="Konk Wheel Game"
      ></iframe>
    </div>
  );
}

export default KonkWheel;
