import React, { useState, useEffect } from 'react';
import './AddToHomeScreenPrompt.css';

function AddToHomeScreenPrompt() {
  const [showPrompt, setShowPrompt] = useState(false);

  useEffect(() => {
    // Check if the app is running as installed PWA
    if (window.matchMedia('(display-mode: standalone)').matches) {
      setShowPrompt(false);
    } else {
      // If not in standalone mode, show the prompt after a short delay
      const timer = setTimeout(() => setShowPrompt(true), 2000);
      return () => clearTimeout(timer);
    }
  }, []);

  const handleClose = () => {
    setShowPrompt(false);
  };

  if (!showPrompt) return null;

  return (
    <div className="add-to-homescreen-prompt">
      <button onClick={handleClose} className="close-button">&times;</button>
      <h3>Install KONK App for easy access!</h3>
      <img src="/images/addtohs.png" alt="Add to Home Screen" className="addtohs-image" />
    </div>
  );
}

export default AddToHomeScreenPrompt;