import { useState, useEffect } from 'react';
import { useWriteContract, useWaitForTransactionReceipt } from 'wagmi';

export const useTransaction = () => {
    const { writeContractAsync } = useWriteContract();
    const [txHash, setTxHash] = useState(null);
    
    const { 
        data: receipt,
        isSuccess,
        isError,
        isPending 
    } = useWaitForTransactionReceipt({
        hash: txHash,
        enabled: !!txHash,
        onReplaced: (replacement) => {
            console.log('Transaction was replaced:', replacement);
        }
    });

    const sendTransaction = async (params) => {
        try {
            const hash = await writeContractAsync(params);
            setTxHash(hash);
            return hash;
        } catch (err) {
            throw err;
        }
    };

    return {
        sendTransaction,
        txHash,
        receipt,
        isSuccess,
        isError,
        isPending,
        resetTxHash: () => setTxHash(null)
    };
};