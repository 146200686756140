import { useState, useCallback, useRef } from 'react';

export const useNotifications = () => {
    const [notification, setNotification] = useState({ 
        show: false, 
        message: '', 
        hash: '',
        isClosing: false 
    });
    const timerRef = useRef(null);

    const showNotification = useCallback(({ message, hash = '', show = true }) => {
        // Clear any existing timer
        if (timerRef.current) {
            clearTimeout(timerRef.current);
        }

        if (show) {
            // Show the notification immediately
            setNotification({ show: true, message, hash, isClosing: false });

            // Only start the timer if it's a success or error message
            if (message.includes('successful') || message.includes('failed')) {
                timerRef.current = setTimeout(() => {
                    // Start fade out animation
                    setNotification(prev => ({ ...prev, isClosing: true }));
                    
                    // Remove notification after animation completes
                    setTimeout(() => {
                        setNotification({ show: false, message: '', hash: '', isClosing: false });
                    }, 500); // Match this with your CSS animation duration
                }, 3000); // Start fade after 3 seconds
            }
        } else {
            // Handle manual close
            setNotification(prev => ({ ...prev, isClosing: true }));
            setTimeout(() => {
                setNotification({ show: false, message: '', hash: '', isClosing: false });
            }, 500);
        }
    }, []);

    return {
        notification,
        showNotification
    };
};