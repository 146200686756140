import { useEffect } from 'react';

export const useTransactionStatus = ({
    txHash,
    isPending,
    isSuccess,
    isError,
    receipt,
    showNotification,
    onSuccess,
    onError,
}) => {
    useEffect(() => {
        if (!txHash) return;

        if (isPending) {
            showNotification({
                message: 'Transaction pending...',
                hash: txHash,
                show: true
            });
        }

        if (isSuccess && receipt) {
            showNotification({
                message: 'Transaction successful! 🚀',
                hash: txHash,
                show: true
            });
            onSuccess?.(receipt);
        }

        if (isError) {
            showNotification({
                message: 'Transaction failed',
                hash: txHash,
                show: true
            });
            onError?.();
        }
    }, [txHash, isPending, isSuccess, isError, receipt, showNotification, onSuccess, onError]);
};

export default useTransactionStatus;