import React, { useEffect, useRef } from 'react';

const KonkRun = () => {
  const gameContainerRef = useRef(null);

  useEffect(() => {
    const gameContainer = gameContainerRef.current;
    if (!gameContainer) return;

    // Load game scripts
    const script = document.createElement('script');
    script.src = '/dapps/konkrun/script.js';
    script.async = true;
    gameContainer.appendChild(script);

    const walletScript = document.createElement('script');
    walletScript.src = '/dapps/konkrun/walletConnect.js';
    walletScript.async = true;
    gameContainer.appendChild(walletScript);

    // Load game styles
    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.href = '/dapps/konkrun/style.css';
    document.head.appendChild(link);

    return () => {
      // Cleanup
      script.remove();
      walletScript.remove();
      link.remove();
    };
  }, []);

  return (
    <div ref={gameContainerRef}>
      <div id="logo">
        <img src="/dapps/konkrun/images/KONKRUNLOGO.png" alt="Konkrun Logo" />
      </div>
      <button id="connectWalletButton">Connect Metamask</button>
      <div id="wrapper">
        <div id="gameContainer">
          <div id="playerCharacter"></div>
          <div id="gameObstacle"></div>
          <img id="playButton" src="/dapps/konkrun/images/play_button.png" alt="Play Button" />
          <img id="restartButton" src="/dapps/konkrun/images/restart_button.png" alt="Restart Button" />
          <div id="scoreContainer">
            <img src="/dapps/konkrun/images/coin.png" alt="Coin" id="coinIcon" /> Score: <span id="score">0</span>
          </div>
        </div>
      </div>
      <div id="controls">
        <h2>CONTROLS:</h2>
        <p>Tap: Jump</p>
        <p>Swipe Up: High Jump</p>
      </div>
      <div id="verificationStatus">
        <div className="loader"></div>
        <div id="verificationMessage"></div>
      </div>
    </div>
  );
};

export default KonkRun;