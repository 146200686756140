import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { ConnectButton } from '@rainbow-me/rainbowkit';
import { useAccount, useBalance, useContractRead, useWriteContract, useWaitForTransactionReceipt, useTransactionReceipt, usePublicClient } from 'wagmi';
import { parseEther, formatEther } from 'viem';
import { stakingContractABI, poolsContractABI } from './abi';
import './KonkHyperMining.css';

const STAKING_CONTRACT_ADDRESS = '0x90fC7ABB6734dF30b9dC726f6f92EefD9c3c7Bb2';
const KONK_TOKEN_ADDRESS = '0x2b9712190ce9570c1ca860d55b1623bd88bf96ff';
const POOLS_CONTRACT_ADDRESS = '0xb82619C0336985e3EDe16B97b950E674018925Bb';
const BOT_WALLET_ADDRESS = '0xdB14eEd121138c4C44F2Bd2441980CeC80539Df9';

const multipliers = [1, 2, 3, 4, 5, 10, 25, 50];

const CustomConnectButton = () => {
  return (
    <ConnectButton.Custom>
      {({
        account,
        chain,
        openAccountModal,
        openConnectModal,
        mounted,
      }) => {
        const ready = mounted;
        const connected = ready && account && chain;

        return (
          <div
            {...(!ready && {
              'aria-hidden': true,
              'style': {
                opacity: 0,
                pointerEvents: 'none',
                userSelect: 'none',
              },
            })}
          >
            {(() => {
              if (!connected) {
                return (
                  <button onClick={openConnectModal} type="button" className="connect-button">
                    Connect Wallet
                  </button>
                );
              }

              return (
                <div style={{ display: 'flex', gap: 12 }}>
                  <button
                    onClick={openAccountModal}
                    type="button"
                    className="connect-button"
                  >
                    {account.displayName}
                    {account.displayBalance
                      ? ` (${account.displayBalance})`
                      : ''}
                  </button>
                </div>
              );
            })()}
          </div>
        );
      }}
    </ConnectButton.Custom>
  );
};

const KonkHyperMining = () => {
  const [stakeAmount, setStakeAmount] = useState('');
  const [depositAmount, setDepositAmount] = useState('');
  const [miningStyle, setMiningStyle] = useState('SMART RAMP');
  const [sliderValue, setSliderValue] = useState(0);
  const [errorMessage, setErrorMessage] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [isApproved, setIsApproved] = useState(false);
  const [activeAction, setActiveAction] = useState('stake');
  const [fixedDepositAmount, setFixedDepositAmount] = useState('Loading...');
  const [poolLogs, setPoolLogs] = useState('');
  const [personalLogs, setPersonalLogs] = useState('');
  const [pendingTxHash, setPendingTxHash] = useState(null);
  const [transactionStatus, setTransactionStatus] = useState('');
  const [showOkButton, setShowOkButton] = useState(true);
  const [showConfirmButton, setShowConfirmButton] = useState(false);
  const [confirmAction, setConfirmAction] = useState(() => {});
  const [cancelAction, setCancelAction] = useState(() => {});
  const [hypersRewards, setHypersRewards] = useState('0');
  const [previousSessionRewards, setPreviousSessionRewards] = useState('0');
  const [timeUntilUnlock, setTimeUntilUnlock] = useState('');
  const [remainingMiningFunds, setRemainingMiningFunds] = useState('0');
  const [isMiningInProgress, setIsMiningInProgress] = useState(false);
  const [isMaintenanceMode, setIsMaintenanceMode] = useState(false);
  const [currentDeposit, setCurrentDeposit] = useState('0');

  const { address } = useAccount();
  const publicClient = usePublicClient();

  const { data: konkBalance } = useBalance({
    address,
    token: KONK_TOKEN_ADDRESS,
  });

  const { data: stakedBalance, refetch: refetchStakedBalance } = useContractRead({
    address: STAKING_CONTRACT_ADDRESS,
    abi: stakingContractABI,
    functionName: 'balanceOf',
    args: [address],
  });

  const { data: rewardAmount, refetch: refetchReward } = useContractRead({
    address: STAKING_CONTRACT_ADDRESS,
    abi: stakingContractABI,
    functionName: 'earned',
    args: [address],
  });

  const { data: allowance, refetch: refetchAllowance } = useContractRead({
    address: KONK_TOKEN_ADDRESS,
    abi: [{"inputs":[{"internalType":"address","name":"owner","type":"address"},{"internalType":"address","name":"spender","type":"address"}],"name":"allowance","outputs":[{"internalType":"uint256","name":"","type":"uint256"}],"stateMutability":"view","type":"function"}],
    functionName: 'allowance',
    args: [address, STAKING_CONTRACT_ADDRESS],
  });

  const { data: fixedDeposit } = useContractRead({
    address: POOLS_CONTRACT_ADDRESS,
    abi: poolsContractABI,
    functionName: 'fixedDeposit',
  });

  const { data: poolCap } = useContractRead({
    address: POOLS_CONTRACT_ADDRESS,
    abi: poolsContractABI,
    functionName: 'poolCap',
  });

  const { data: poolInfo, refetch: refetchPoolInfo } = useContractRead({
    address: POOLS_CONTRACT_ADDRESS,
    abi: poolsContractABI,
    functionName: 'getPoolInfo',
  });

  const { data: miningStatus, refetch: refetchMiningStatus } = useContractRead({
    address: POOLS_CONTRACT_ADDRESS,
    abi: poolsContractABI,
    functionName: 'getMiningStatus',
  });

  const { data: userInfo, refetch: refetchUserInfo } = useContractRead({
    address: POOLS_CONTRACT_ADDRESS,
    abi: poolsContractABI,
    functionName: 'getUserInfo',
    args: [address],
  });

  const { data: botBalance } = useBalance({
    address: BOT_WALLET_ADDRESS,
  });

  const { writeContract, data: writeData, error: writeError, isPending: isWritePending } = useWriteContract();

  const { 
    isLoading: isWaitingForTransaction, 
    isSuccess: isTransactionSuccess,
    isError: isTransactionError
  } = useWaitForTransactionReceipt({
    hash: pendingTxHash,
  });

  const { data: transactionReceipt } = useTransactionReceipt({
    hash: pendingTxHash,
  });

  const { data: hypersBalance } = useBalance({
    address,
    token: '0xf8797db8a9eed416ca14e8dfaede2bf4e1aabfc3', // Replace with the actual HYPERS token address
  });

  const { data: poolHypersBalance } = useBalance({
    address: POOLS_CONTRACT_ADDRESS,
    token: '0xf8797db8a9eed416ca14e8dfaede2bf4e1aabfc3',
  });

  const fetchPreviousRewards = useCallback(async () => {
    if (!address) return;
  
    try {
      // Get the current session ID
      const currentSessionId = await publicClient.readContract({
        address: POOLS_CONTRACT_ADDRESS,
        abi: poolsContractABI,
        functionName: 'currentSessionId',
      });
  
      // The previous session ID is just the current one minus 1
      const previousSessionId = currentSessionId - 1n;
  
      console.log("Fetching rewards for session:", previousSessionId.toString());
  
      // Fetch the RewardDistributed event for this user in the previous session
      const rewardDistributedEvents = await publicClient.getContractEvents({
        address: POOLS_CONTRACT_ADDRESS,
        abi: poolsContractABI,
        eventName: 'RewardDistributed',
        args: {
          user: address,
          sessionId: previousSessionId
        },
        fromBlock: 'earliest',
        toBlock: 'latest'
      });
  
      if (rewardDistributedEvents.length > 0) {
        const userReward = rewardDistributedEvents[0].args.amount;
        const formattedReward = formatEther(userReward);
        console.log("Previous session rewards:", formattedReward);
        setPreviousSessionRewards(formattedReward);
      } else {
        console.log("No rewards found for the user in the previous session");
        setPreviousSessionRewards('0');
      }
    } catch (error) {
      console.error("Error fetching previous rewards:", error);
      setPreviousSessionRewards('0');
    }
  }, [address, publicClient]);

  useEffect(() => {
    if (address) {
      fetchPreviousRewards();
    }
  }, [address, fetchPreviousRewards]);

  const updateLogs = useCallback(() => {
  if (poolInfo && miningStatus && poolCap && botBalance) {
    const [sessionId, isLocked, totalDeposits, smartRampVotes, aggressiveVotes, contributorCount] = poolInfo;
    const [miningInProgress, currentBot] = miningStatus;

    const poolCapEth = parseFloat(formatEther(poolCap ?? '0'));
    const totalDepositsEth = parseFloat(formatEther(totalDeposits ?? '0'));
    const remainingCapacity = Math.max(0, poolCapEth - totalDepositsEth);

    const botBalanceEth = parseFloat(formatEther(botBalance.value ?? '0'));
    const remainingFunds = Math.max(0, botBalanceEth - 0.0125);
    setRemainingMiningFunds(remainingFunds.toFixed(6));

    let unlockTime = '';
    if (remainingFunds > 0) {
      const hoursRemaining = (remainingFunds / 0.1) * 24;
      unlockTime = `${hoursRemaining.toFixed(1)} hrs`;
    } else {
      unlockTime = isLocked ? 'Deposits are closed' : 'Deposits are open';
    }
    setTimeUntilUnlock(unlockTime);

    const poolHypersAmount = poolHypersBalance ? formatEther(poolHypersBalance.value) : '0';

    setPoolLogs(`Session ID: ${sessionId?.toString() ?? 'N/A'}
Pool Locked: ${isLocked ? 'Yes' : 'No'}
Mining In Progress: ${miningInProgress ? 'Yes' : 'No'}
Deposits Unlocking In: ~${unlockTime}
HYPERS Mined: ${poolHypersAmount} HYPERS

Total Deposits: ${formatEther(totalDeposits ?? '0')} ETH
Remaining Pool Capacity: ${remainingCapacity.toFixed(6)} ETH
Contributors: ${contributorCount?.toString() ?? '0'}

SmartRamp Votes: ${formatEther(smartRampVotes ?? '0')} ETH
Aggressive Votes: ${formatEther(aggressiveVotes ?? '0')} ETH

Current Bot: ${currentBot === '0x0000000000000000000000000000000000000000' ? 'None' : currentBot ?? 'N/A'}
Remaining Funds for Mining: ${remainingFunds.toFixed(6)} ETH`);

    setIsMiningInProgress(miningInProgress);

    // Calculate user's potential HYPERS rewards
    const currentDepositAmount = userInfo ?? '0';
    const userDepositEth = parseFloat(formatEther(currentDepositAmount));
    const hypersBalanceAmount = hypersBalance?.value ?? '0';
    const poolHypersValue = poolHypersBalance?.value ?? '0';

    let userHypersRewards = '0';
    if (totalDepositsEth > 0 && poolHypersValue > 0) {
      const userProportion = userDepositEth / totalDepositsEth;
      userHypersRewards = (parseFloat(formatEther(poolHypersValue)) * userProportion).toFixed(6);
    }

    setPersonalLogs(`Current Deposit: ${formatEther(currentDepositAmount)} ETH
Your HYPERS Balance: ${formatEther(hypersBalanceAmount)} HYPERS
Your HYPERS Rewards: ${userHypersRewards} HYPERS
${address ? `Your wallet: ${address}` : 'Please connect your wallet to see more information.'}

HYPERS rewards are airdropped to depositor's wallets after the completion of the mining session.`);
  }
}, [poolInfo, miningStatus, userInfo, poolCap, botBalance, address, hypersBalance, poolHypersBalance]);


  useEffect(() => {
    updateLogs();
  }, [updateLogs, poolInfo, miningStatus, userInfo, botBalance]);

  useEffect(() => {
    const interval = setInterval(() => {
      refetchPoolInfo();
      refetchMiningStatus();
      refetchUserInfo();
      fetchPreviousRewards();
    }, 30000); // Refresh every 30 seconds
  
    return () => clearInterval(interval);
  }, [refetchPoolInfo, refetchMiningStatus, refetchUserInfo, fetchPreviousRewards]);

  useEffect(() => {
    if (fixedDeposit) {
      const fixedDepositEth = formatEther(fixedDeposit);
      setFixedDepositAmount(fixedDepositEth);
      setDepositAmount(fixedDepositEth);
    }
  }, [fixedDeposit]);

  useEffect(() => {
    if (allowance && stakeAmount) {
      setIsApproved(allowance >= parseEther(stakeAmount));
    }
  }, [allowance, stakeAmount]);

  useEffect(() => {
    if (writeData?.hash) {
      setPendingTxHash(writeData.hash);
      setTransactionStatus('pending');
      setModalMessage("Transaction sent. Waiting for confirmation...");
      setShowModal(true);
      setShowOkButton(false);
    }
  }, [writeData]);

  useEffect(() => {
    if (isTransactionSuccess && transactionReceipt) {
      setTransactionStatus('success');
      setModalMessage("Transaction successful!");
      setShowOkButton(true);
      refetchPoolInfo();
      refetchMiningStatus();
      refetchUserInfo();
      refetchStakedBalance();
      refetchReward();
      refetchAllowance();
    } else if (isTransactionError) {
      setTransactionStatus('error');
      setModalMessage("Transaction failed. Please try again.");
      setShowOkButton(true);
    }
  }, [isTransactionSuccess, isTransactionError, transactionReceipt, refetchPoolInfo, refetchMiningStatus, refetchUserInfo, refetchStakedBalance, refetchReward, refetchAllowance]);

  useEffect(() => {
    if (writeError) {
      setTransactionStatus('error');
      if (writeError.message.includes('user rejected transaction')) {
        setModalMessage("Transaction rejected by user.");
      } else {
        setModalMessage(`Transaction error: ${writeError.message}`);
      }
      setShowModal(true);
    }
  }, [writeError]);

  const handleApproveAndStake = async () => {
    if (!stakeAmount || !address) return;

    try {
      setTransactionStatus('initializing');
      setModalMessage("Initializing transaction...");
      setShowModal(true);

      if (!isApproved) {
        await writeContract({
          address: KONK_TOKEN_ADDRESS,
          abi: [{"inputs":[{"internalType":"address","name":"spender","type":"address"},{"internalType":"uint256","name":"amount","type":"uint256"}],"name":"approve","outputs":[{"internalType":"bool","name":"","type":"bool"}],"stateMutability":"nonpayable","type":"function"}],
          functionName: 'approve',
          args: [STAKING_CONTRACT_ADDRESS, parseEther(stakeAmount)],
        });
      } else {
        await handleStake();
      }
    } catch (error) {
      console.error('Transaction failed:', error);
      setTransactionStatus('error');
      setModalMessage(`Transaction failed: ${error.message}`);
      setShowModal(true);
    }
  };

  const handleStake = async () => {
    if (stakeAmount && address) {
      try {
        setTransactionStatus('initializing');
        setModalMessage("Initializing stake transaction...");
        setShowModal(true);
        await writeContract({
          address: STAKING_CONTRACT_ADDRESS,
          abi: stakingContractABI,
          functionName: 'deposit',
          args: [parseEther(stakeAmount), address],
        });
      } catch (error) {
        console.error('Staking failed:', error);
        setTransactionStatus('error');
        setModalMessage(`Staking failed: ${error.message}`);
        setShowModal(true);
      }
    }
  };

  const handleUnstake = async () => {
    if (stakeAmount && address) {
      try {
        setTransactionStatus('initializing');
        setModalMessage("Initializing unstake transaction...");
        setShowModal(true);
        await writeContract({
          address: STAKING_CONTRACT_ADDRESS,
          abi: stakingContractABI,
          functionName: 'withdraw',
          args: [parseEther(stakeAmount), address, address],
        });
      } catch (error) {
        console.error('Unstaking failed:', error);
        setTransactionStatus('error');
        setModalMessage(`Unstaking failed: ${error.message}`);
        setShowModal(true);
      }
    }
  };

  const handleDeposit = async () => { 
    if (isMiningInProgress || isMaintenanceMode) {
      setModalMessage("Deposits are currently not allowed. Please wait for the next session.");
      setShowModal(true);
      return;
    }
  
    const fixedDepositEth = fixedDeposit ? parseFloat(formatEther(fixedDeposit)) : 0;
  
    if (fixedDepositEth === 0) {
      setModalMessage("Fixed deposit is not set correctly.");
      setShowModal(true);
      return;
    }
  
    try {
      await refetchPoolInfo();
  
      const poolCapEth = poolCap ? parseFloat(formatEther(poolCap)) : 0;
      const currentPoolDeposits = poolInfo?.totalDeposits ? parseFloat(formatEther(poolInfo.totalDeposits)) : 0;
  
      if (poolCapEth === 0) {
        throw new Error("Pool cap is not set correctly.");
      }
  
      const multiplier = Math.floor(parseFloat(depositAmount) / fixedDepositEth);
      const totalDeposit = fixedDepositEth * multiplier;
      const remainingCap = poolCapEth - currentPoolDeposits;
  
      if (totalDeposit > remainingCap) {
        const maxDepositPossible = Math.floor(remainingCap / fixedDepositEth) * fixedDepositEth;
        setModalMessage(`Your deposit exceeds the remaining pool capacity. You can only deposit up to ${maxDepositPossible.toFixed(6)} ETH.`);
        setShowModal(true);
        return;
      }
  
      const baseFeePercentage = 0.025; // 2.5%
      const stakedKonk = stakedBalance ? parseFloat(formatEther(stakedBalance)) : 0;
      const isEligibleForDiscount = stakedKonk >= 5555;
      const feePercentage = isEligibleForDiscount ? baseFeePercentage * 0.95 : baseFeePercentage;
      const feeAmount = totalDeposit * feePercentage;
      const totalAmount = totalDeposit + feeAmount;
  
      const confirmationMessage = (
        <>
          Please confirm your deposit:<br />
          Deposit Amount: {totalDeposit.toFixed(6)} ETH<br />
          General Fee: 2.5%<br />
          Staker Discount: {isEligibleForDiscount ? '5% Applied' : 'Not Applicable'}<br />
          Fee Amount: {feeAmount.toFixed(6)} ETH<br />
          Total Amount to Send: {totalAmount.toFixed(6)} ETH<br />
          Voted Mining Style: <strong>{miningStyle}</strong>
        </>
      );
  
      setModalMessage(confirmationMessage);
      setShowConfirmButton(true);
      setConfirmAction(() => () => handleConfirmDeposit(multiplier, totalAmount));
      setCancelAction(() => () => {
        setShowModal(false);
        setShowConfirmButton(false);
      });
      setShowModal(true);
  
    } catch (error) {
      console.error('Deposit preparation failed:', error);
      setTransactionStatus('error');
      setModalMessage(`Deposit preparation failed: ${error.message}`);
      setShowModal(true);
    }
  };
  
  const handleConfirmDeposit = async (multiplier, totalAmount) => {
    try {
      setShowConfirmButton(false);
      setTransactionStatus('initializing');
      setModalMessage("Initializing deposit transaction...");
  
      // Round to 8 decimal places to avoid floating-point issues
      const roundedAmount = parseFloat(totalAmount).toFixed(8);
      const totalAmountWei = parseEther(roundedAmount.toString());
      console.log('Final deposit amount in wei:', totalAmountWei.toString());
  
      const result = await writeContract({
        address: POOLS_CONTRACT_ADDRESS,
        abi: poolsContractABI,
        functionName: 'multiDeposit',
        args: [multiplier, miningStyle === 'SMART RAMP'],
        value: totalAmountWei,
      });
  
      setTransactionStatus('pending');
      setModalMessage("Transaction sent. Waiting for confirmation...");
      
      if (result && result.hash) {
        setPendingTxHash(result.hash);
      } else {
        console.warn('Unable to retrieve transaction hash, but transaction was sent');
      }
  
    } catch (error) {
      console.error('Deposit failed:', error);
      setTransactionStatus('error');
      setModalMessage(`Deposit failed: ${error.message}`);
    }
    setShowModal(true);
  };
  

  const handleSliderChange = (e) => {
    const value = parseInt(e.target.value);
    setSliderValue(value);
    const newDepositAmount = (parseFloat(fixedDepositAmount) * multipliers[value]).toFixed(6);
    setDepositAmount(newDepositAmount);
  };

  const setPercentage = (percentage) => {
    const balance = activeAction === 'stake' ? konkBalance?.value : stakedBalance;
    if (balance) {
      const amount = (parseFloat(formatEther(balance)) * percentage).toFixed(6);
      setStakeAmount(amount);
    }
  };

  const handleActionChange = (action) => {
    setActiveAction(action);
    setStakeAmount('');
  };

  const resetTransactionState = async () => {
    setShowModal(false);
    setPendingTxHash(null);
    setTransactionStatus('');
    setShowOkButton(true);
    refetchPoolInfo();
    refetchMiningStatus();
    refetchUserInfo();
    refetchStakedBalance();
    refetchReward();
    refetchAllowance();
  };

  const toggleMaintenanceMode = () => {
    setIsMaintenanceMode(!isMaintenanceMode);
  };

  const ActionButton = ({ onClick, isLoading, children, disabled }) => (
    <button 
      onClick={onClick}
      disabled={disabled || isLoading || isWritePending || isWaitingForTransaction}
      className={isLoading || isWritePending || isWaitingForTransaction ? 'loading' : ''}
    >
      {isLoading || isWritePending || isWaitingForTransaction ? 'Processing...' : children}
    </button>
  );

  return (
    <div className="konk-hyper-mining">
      <header>
        <Link to="/" className="home-icon"><i className="fas fa-home"></i></Link>
        <h1 className="vt323-regular">KONK HYPERS MINING POOLS</h1>
        <div className="header-right">
          <CustomConnectButton />
          <div className="social-icons">
            <a href="https://hypers.money" target="_blank" rel="noopener noreferrer">
              <img src="./images/hypersound.png" alt="Hypersound" className="hypersound-icon" />
            </a>
            <a href="https://kiyosonk.xyz" target="_blank" rel="noopener noreferrer" className="social-icon"><i className="fas fa-globe"></i></a>
            <a href="https://x.com/kiyosonk" target="_blank" rel="noopener noreferrer" className="social-icon"><i className="fab fa-twitter"></i></a>
            <a href="https://t.me/kiyosonk" target="_blank" rel="noopener noreferrer" className="social-icon"><i className="fab fa-telegram"></i></a>
            <a href="https://kiyosonk.gitbook.io/konk-hypers-pool/" target="_blank" rel="noopener noreferrer" className="social-icon"><i className="fas fa-file-alt"></i></a>
          </div>
        </div>
      </header>
  
      <main>
        <section className="staking-section">
          <h2 className="vt323-regular">STEP1: Staking(Optional)</h2>
          <div className="konk-staking">
            <div className="staking-header">
              <img src="./images/konk.png" alt="KONK" className="konk-image" />
              <h3 className="vt323-regular">KONK STAKING</h3>
            </div>
            <p>STAKE $KONK TO EARN STAKING REWARD +<br />5% DISCOUNT IN PLATFORM FEE IF STAKED AMOUNT IS MORE THAN 5555 $KONK</p>
            <div className="staking-options">
              <button 
                className={`option-button ${activeAction === 'stake' ? 'active' : ''}`}
                onClick={() => handleActionChange('stake')}
              >
                STAKE KONK
              </button>
              <button 
                className={`option-button ${activeAction === 'unstake' ? 'active' : ''}`}
                onClick={() => handleActionChange('unstake')}
              >
                UNSTAKE KONK
              </button>
            </div>
            <div className="staking-controls">
              <div className="amount-input">
                <label>AMOUNT</label>
                <input 
                  type="number" 
                  value={stakeAmount} 
                  onChange={(e) => setStakeAmount(e.target.value)} 
                  placeholder="5555"
                />
                <div className="amount-buttons">
                  <button onClick={() => setPercentage(0)}>RESET</button>
                  <button onClick={() => setPercentage(0.25)}>25%</button>
                  <button onClick={() => setPercentage(0.5)}>50%</button>
                  <button onClick={() => setPercentage(0.75)}>75%</button>
                  <button onClick={() => setPercentage(1)}>100%</button>
                </div>
              </div>
              <div className="staking-stats">
                <p>Total Staked: {stakedBalance ? formatEther(stakedBalance) : '0'} $KONK</p>
                <p>KONK in wallet: {konkBalance ? formatEther(konkBalance.value) : '0'} $KONK</p>
              </div>
              {activeAction === 'stake' ? (
                <ActionButton
                  onClick={handleApproveAndStake}
                  isLoading={isWritePending || isWaitingForTransaction}
                >
                  {isApproved ? 'Stake Now' : 'Approve & Stake'}
                </ActionButton>
              ) : (
                <ActionButton
                  onClick={handleUnstake}
                  isLoading={isWritePending || isWaitingForTransaction}
                >
                  UNSTAKE KONK
                </ActionButton>
              )}
            </div>
            {errorMessage && <div className="error-message">{errorMessage}</div>}
            <a href="https://tornado.fun/token/0x2b9712190ce9570c1ca860d55b1623bd88bf96ff" target="_blank" rel="noopener noreferrer" className="buy-konk">BUY KONK ON TORNADO</a>
          </div>
        </section>
  
        <section className="deposit-section">
          <h2 className="vt323-regular">STEP2: Deposit ETH</h2>
          <div className="konk-hypers-pool">
            <h3 className="vt323-regular">KONK HYPERS POOL</h3>
            <div className="pool-info">
              <p>MIN DEPOSIT: {fixedDepositAmount} ETH</p>
              <p>FEES: 2.5% ON DEPOSIT</p>
              <p>DEPOSIT LIMIT: Upto Pool Cap</p>
              <p>POOL CAP: {poolCap ? formatEther(poolCap) : 'Loading...'} ETH</p>
              {isMiningInProgress && (
                <p className="mining-in-progress">MINING IS IN PROGRESS. DEPOSITS ARE DISABLED.</p>
              )}
              {!isMiningInProgress && timeUntilUnlock && (
                <p className="deposits-unlocking">Deposits Opening in: {timeUntilUnlock}</p>
              )}
            </div>
            <div className="pool-logs">
              <div className="log-box">
                <h4>Pool Logs:</h4>
                <pre>{poolLogs}</pre>
              </div>
              <div className="log-box">
                <h4>Personal Logs:</h4>
                <pre>{personalLogs}</pre>
              </div>
            </div>
            <div className="mining-style">
              <h4>1. Vote for mining style</h4>
              <p>(Smartramp would be prioritised in case of a draw)</p>
              <div className="mining-options">
                <div className="mining-option">
                  <button className={miningStyle === 'SMART RAMP' ? 'active' : ''} onClick={() => setMiningStyle('SMART RAMP')}>SMART RAMP</button>
                  <p>Batch Size: Auto Adjusts<br />
                  MAX COST PER BLOCK: ~=Theoretical value</p>
                </div>
                <div className="mining-option">
                  <button className={miningStyle === 'AGGRESSIVE' ? 'active' : ''} onClick={() => setMiningStyle('AGGRESSIVE')}>AGGRESSIVE</button>
                  <p>BATCH SIZE: 1000 (Fixed)<br />
                  MAX COST PER BLOCK: ~15% more than the theoretical value</p>
                </div>
              </div>
            </div>
            <div className="deposit-controls">
              <h4>2. Deposit ETH in Pool</h4>
              <input 
                type="number" 
                value={depositAmount} 
                readOnly
                step="0.0001"
              />
              <div className="deposit-slider-container">
                <input 
                  type="range" 
                  min="0" 
                  max="7" 
                  value={sliderValue} 
                  onChange={handleSliderChange}
                  className="deposit-slider" 
                />
                <div className="slider-labels">
                {multipliers.map((m, i) => (
                    <span key={i}>{m}x</span>
                  ))}
                </div>
              </div>
              <ActionButton
                onClick={handleDeposit}
                isLoading={isWritePending || isWaitingForTransaction}
                disabled={isMiningInProgress || isMaintenanceMode}
              >
                DEPOSIT
              </ActionButton>
              <p className="fee-note">Note: 2.5% Fee on Deposits!</p>
            </div>
          </div>
        </section>

      </main>
  
      <footer>
        <p>Built with Konkness</p>
        <img src="./images/built-on-blast.png" alt="Built on Blast" />
      </footer>
      <img src="./images/blastoshi.png" alt="Blastoshi" className="blastoshi-image" />

      {showModal && (
        <div className="modal">
          <div className="modal-content">
            <p>{modalMessage}</p>
            {pendingTxHash && (
              <a href={`https://blastscan.io/tx/${pendingTxHash}`} target="_blank" rel="noopener noreferrer">
                View transaction on BlastScan
              </a>
            )}
            {showConfirmButton ? (
              <div className="modal-buttons">
                <button onClick={confirmAction}>Confirm</button>
                <button onClick={cancelAction}>Cancel</button>
              </div>
            ) : (
              <button onClick={resetTransactionState}>OK</button>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default KonkHyperMining;