import React, { createContext, useContext, useState, useEffect } from 'react';
import { useAccount, useChainId } from 'wagmi';

const WalletContext = createContext();

export const WalletProvider = ({ children }) => {
  const { address, isConnected } = useAccount();
  const chainId = useChainId();
  const [walletAddress, setWalletAddress] = useState(null);
  const [connectedChainId, setConnectedChainId] = useState(null);

  useEffect(() => {
    if (isConnected && address) {
      setWalletAddress(address);
    } else {
      setWalletAddress(null);
    }
  }, [isConnected, address]);

  useEffect(() => {
    if (chainId) {
      setConnectedChainId(chainId);
    } else {
      setConnectedChainId(null);
    }
  }, [chainId]);

  return (
    <WalletContext.Provider value={{ walletAddress, connectedChainId, isConnected }}>
      {children}
    </WalletContext.Provider>
  );
};

export const useWallet = () => useContext(WalletContext);