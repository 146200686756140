import React, { useState, useCallback, useEffect } from 'react';
import { ethers } from 'ethers';
import { useWallet } from '../WalletContext';
import { parseEther, formatEther } from 'viem';
import { useWriteContract, usePublicClient, useWaitForTransactionReceipt, useSimulateContract   } from 'wagmi';

import { useSignMessage } from 'wagmi';
import { useConfig } from 'wagmi'
import { Upload, Home, FileText, History, RefreshCcw, AlertCircle, CheckCircle, XCircle } from 'lucide-react';
import { ConnectButton } from '@rainbow-me/rainbowkit';
import { Link } from 'react-router-dom';
// import { BLAST_COMMUNITIES } from '../constants/communities';
import './KONKDrop.css';

const KONK_ADDRESS = '0x2b9712190Ce9570C1CA860D55B1623Bd88BF96fF';
const STKONK_ADDRESS = '0x90fC7ABB6734dF30b9dC726f6f92EefD9c3c7Bb2';
const KONK_DROP_ADDRESS = '0xbc37F9Ce2cEb883192909648b29AE1d5d8154C44';
const BLAST_POINTS_API = 'https://waitlist-api.prod.blast.io/v1';
const BLAST_MIN_GOLD_AMOUNT = 0.01;
const BLAST_MIN_POINTS_AMOUNT = 0.000001;
const BLAST_MAX_BATCH_SIZE = 2000;
const BLAST_MIN_FINALIZE_SECONDS = 3600;

const KONK_DROP_ABI = [
    {
        "inputs": [
            {"internalType": "address[]", "name": "_recipients", "type": "address[]"},
            {"internalType": "uint256[]", "name": "_amounts", "type": "uint256[]"}
        ],
        "name": "disperseETH",
        "outputs": [],
        "stateMutability": "payable",
        "type": "function"
    },
    {
        "inputs": [
            {"internalType": "address", "name": "_token", "type": "address"},
            {"internalType": "address[]", "name": "_recipients", "type": "address[]"},
            {"internalType": "uint256[]", "name": "_amounts", "type": "uint256[]"}
        ],
        "name": "disperseERC20",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {"internalType": "address", "name": "_token", "type": "address"},
            {"internalType": "address[]", "name": "_recipients", "type": "address[]"},
            {"internalType": "uint256[]", "name": "_tokenIds", "type": "uint256[]"}
        ],
        "name": "disperseERC721",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [{"internalType": "address", "name": "_user", "type": "address"}],
        "name": "isEligible",
        "outputs": [{"internalType": "bool", "name": "", "type": "bool"}],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "getStats",
        "outputs": [
            {"internalType": "uint256", "name": "totalDrops", "type": "uint256"},
            {"internalType": "uint256", "name": "totalTokensDropped", "type": "uint256"},
            {"internalType": "uint256", "name": "totalETHDropped", "type": "uint256"},
            {"internalType": "uint256", "name": "totalNFTsDropped", "type": "uint256"},
            {"internalType": "uint256", "name": "totalRecipients", "type": "uint256"}
        ],
        "stateMutability": "view",
        "type": "function"
    }
];

const ERC20_ABI = [
    {
        "inputs": [{"internalType": "address", "name": "account", "type": "address"}],
        "name": "balanceOf",
        "outputs": [{"internalType": "uint256", "name": "", "type": "uint256"}],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {"internalType": "address", "name": "spender", "type": "address"},
            {"internalType": "uint256", "name": "amount", "type": "uint256"}
        ],
        "name": "approve",
        "outputs": [{"internalType": "bool", "name": "", "type": "bool"}],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {"internalType": "address", "name": "owner", "type": "address"},
            {"internalType": "address", "name": "spender", "type": "address"}
        ],
        "name": "allowance",
        "outputs": [{"internalType": "uint256", "name": "", "type": "uint256"}],
        "stateMutability": "view",
        "type": "function"
    }
];

const ERC721_ABI = [
    {
        "inputs": [
            {"internalType": "address", "name": "operator", "type": "address"},
            {"internalType": "bool", "name": "approved", "type": "bool"}
        ],
        "name": "setApprovalForAll",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {"internalType": "address", "name": "owner", "type": "address"},
            {"internalType": "address", "name": "operator", "type": "address"}
        ],
        "name": "isApprovedForAll",
        "outputs": [{"internalType": "bool", "name": "", "type": "bool"}],
        "stateMutability": "view",
        "type": "function"
    }
];

const EligibilityPopup = ({ onClose }) => (
    <div className="eligibility-modal">
        <div className="modal-content">
            <button onClick={onClose} className="close-button">×</button>
            <h3>Need More KONK!</h3>
            <p>You need TO HODL/STAKE at least 10,000 KONK tokens to use KONKDROP.</p>
            <a 
                href="https://app.thruster.finance/?token2=0x2b9712190Ce9570C1CA860D55B1623Bd88BF96fF"
                target="_blank"
                rel="noopener noreferrer"
                className="buy-button"
            >
                BUY KONK
            </a>
        </div>
    </div>
);

const BlastTransactionStatus = {
    PENDING: 'PENDING',
    FINALIZING: 'FINALIZING',
    FINALIZED: 'FINALIZED',
    CANCELLED: 'CANCELLED'
};

const formatBlastAmount = (amount, type) => {
    if (!amount) return '0';
    return parseFloat(amount).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: type === 'PHASE2_GOLD' ? 2 : 6
    }) + (type === 'PHASE2_GOLD' ? ' Gold' : ' Points');
};

const validateBlastTransfers = (transfers, pointType) => {
    if (transfers.length > BLAST_MAX_BATCH_SIZE) {
        throw new Error(`Maximum ${BLAST_MAX_BATCH_SIZE} transfers allowed per batch`);
    }

    const minAmount = pointType === 'PHASE2_GOLD' ? BLAST_MIN_GOLD_AMOUNT : BLAST_MIN_POINTS_AMOUNT;
    const invalidTransfers = transfers.filter(t => parseFloat(t.points) < minAmount);
    
    if (invalidTransfers.length > 0) {
        throw new Error(`Minimum amount for ${pointType === 'PHASE2_GOLD' ? 'Gold' : 'Points'} is ${minAmount}`);
    }

    return true;
};

const TransactionNotification = ({ show, message, hash, onClose }) => {
    if (!show) return null;
    
    return (
        <div className="transaction-notification">
            <div className="notification-content">
                <div className="notification-message">
                    <CheckCircle className="success-icon" size={18} />
                    <span>{message}</span>
                </div>
                {hash && (
                    <a 
                        href={`https://blastscan.io/tx/${hash}`} 
                        target="_blank" 
                        rel="noopener noreferrer"
                        className="transaction-hash"
                    >
                        Tx: {hash.slice(0, 6)}...{hash.slice(-4)}
                    </a>
                )}
                <button className="close-notification" onClick={onClose}>×</button>
            </div>
        </div>
    );
};

const KONKDrop = () => {
    const config = useConfig()
    const { signMessageAsync } = useSignMessage();
    const { walletAddress, isConnected } = useWallet();
    const publicClient = usePublicClient();
    const { writeContract } = useWriteContract();
    // const { waitForTransactionReceipt } = useWaitForTransactionReceipt();
    const waitForTransaction = useWaitForTransactionReceipt();

    // All state declarations in one place
    const [mode, setMode] = useState('eth');
    const [showPopup, setShowPopup] = useState(false);
    const [walletInput, setWalletInput] = useState('');
    const [tokenAddress, setTokenAddress] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [stats, setStats] = useState({
        totalDrops: '0',
        totalTokensDropped: '0',
        totalETHDropped: '0',
        totalNFTsDropped: '0',
        totalRecipients: '0'
    });
    const [inputSummary, setInputSummary] = useState({ totalAmount: '0', totalWallets: 0 });
    const [isUserEligible, setIsUserEligible] = useState(false);
    const [richKonksCount, setRichKonksCount] = useState(0);
    const [amountPerKonk, setAmountPerKonk] = useState('');
    const [loadingMessage, setLoadingMessage] = useState('');
    const [richKonksError, setRichKonksError] = useState('');
    const [selectedCommunity, setSelectedCommunity] = useState('');
    const [minHolding, setMinHolding] = useState('');
    const [distributionMode, setDistributionMode] = useState('equal');
    const [holdersData, setHoldersData] = useState(null);

    // Blast-specific state
    const [contractAddress, setContractAddress] = useState(''); // For the smart contract that has points
    const [blastAuth, setBlastAuth] = useState(null);
    const [blastBalance, setBlastBalance] = useState(null);
    const [pointType, setPointType] = useState('PHASE2_GOLD');
    const [refreshingBalance, setRefreshingBalance] = useState(false);
    const [blastTransactions, setBlastTransactions] = useState([]);
    const [showTransactionHistory, setShowTransactionHistory] = useState(false);
    const [currentBatchId, setCurrentBatchId] = useState(null);
    const [batchStatus, setBatchStatus] = useState(null);
    const [showBatchManagement, setShowBatchManagement] = useState(false);
    const [batchLookupId, setBatchLookupId] = useState('');
    const [showLeaderboardForm, setShowLeaderboardForm] = useState(false);
    const [leaderboardInfo, setLeaderboardInfo] = useState({
        name: '',
        category: '',
        twitter: '',
        imageUrl: ''
    });
    
    const BLAST_CATEGORIES = [
        'Perp Dex',
        'Lending',
        'Spot Dex',
        'SocialFi',
        'NFTs/Gaming',
        'GambleFi',
        'Infra',
        'RWA',
        'Prediction Market',
        'Misc'
    ];

    const { writeContractAsync } = useWriteContract();
    const [txHash, setTxHash] = useState(null);
    const { data: simulateData, error: simulateError } = useSimulateContract({
        address: KONK_DROP_ADDRESS,
        abi: KONK_DROP_ABI,
    });

    const { 
        data: receipt,
        isSuccess,
        isError,
        isPending 
    } = useWaitForTransactionReceipt({
        hash: txHash,
        enabled: !!txHash,
    });

    const [isApproving, setIsApproving] = useState(false);
    const [isApproved, setIsApproved] = useState(false);
    const [isDistributing, setIsDistributing] = useState(false);
    const [notification, setNotification] = useState({ show: false, message: '', hash: '' });
    const [errorTimer, setErrorTimer] = useState(null);

    const showError = (message) => {
        // Clear any existing error timer
        if (errorTimer) clearTimeout(errorTimer);
        
        // Set the error
        setError(message);
        
        // Set a new timer to clear the error after 5 seconds
        const timer = setTimeout(() => {
            setError('');
        }, 5000);
        
        setErrorTimer(timer);
    };

    const formatErrorMessage = (error) => {
        const errorMessage = error?.message || error;
        
        // Common error patterns
        if (errorMessage?.includes('user rejected transaction')) {
            return 'Transaction was rejected';
        }
        if (errorMessage?.includes('insufficient funds')) {
            return 'Insufficient balance for this transaction';
        }
        if (errorMessage?.includes('gas required exceeds allowance')) {
            return 'Insufficient gas for this transaction';
        }
        if (errorMessage?.includes('nonce too high')) {
            return 'Please reset your wallet or try again';
        }
        if (errorMessage?.includes('transaction failed')) {
            return 'Transaction failed. Please try again';
        }
    
        // Return a generic message if no specific error is matched
        return 'Transaction failed. Please try again';
    };

    const registerOnLeaderboard = async () => {
        if (!blastAuth || !walletAddress) {
            setError('Please authenticate first');
            return;
        }
    
        try {
            setLoading(true);
            const response = await fetch(
                `/api/blast/leaderboard?operatorAddress=${walletAddress}&token=${blastAuth}`,
                {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(leaderboardInfo)
                }
            );
    
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Failed to register on leaderboard');
            }
    
            setError('Successfully registered on leaderboard!');
            setShowLeaderboardForm(false);
        } catch (err) {
            console.error('Leaderboard registration error:', err);
            setError(err.message || 'Failed to register on leaderboard');
        } finally {
            setLoading(false);
        }
    };
    // Your existing checkEligibility function
    const checkEligibility = useCallback(async () => {
        if (!isConnected || !walletAddress || !publicClient) return;

        try {
            const [konkBal, stkonkBal] = await Promise.all([
                publicClient.readContract({
                    address: KONK_ADDRESS,
                    abi: ERC20_ABI,
                    functionName: 'balanceOf',
                    args: [walletAddress],
                }),
                publicClient.readContract({
                    address: STKONK_ADDRESS,
                    abi: ERC20_ABI,
                    functionName: 'balanceOf',
                    args: [walletAddress],
                })
            ]);

            const totalBalance = Number(formatEther(konkBal)) + Number(formatEther(stkonkBal));
            setIsUserEligible(totalBalance >= 0);
        } catch (err) {
            console.error('Error checking eligibility:', err);
            setIsUserEligible(false);
        }
    }, [isConnected, walletAddress, publicClient]);

    useEffect(() => {
        checkEligibility();
    }, [checkEligibility]);

    // Blast authentication
    const authenticateBlast = async () => {
        if (!contractAddress || !walletAddress) {
            setError('Please enter the Contract Address that has Blast Points/Gold');
            return;
        }
    
        try {
            setLoading(true);
            setError('');
    
            const challengeResponse = await fetch('/api/blast/challenge', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    contractAddress: contractAddress,
                    operatorAddress: walletAddress
                })
            });
    
            if (!challengeResponse.ok) {
                const errorData = await challengeResponse.json();
                throw new Error(errorData.message || 'Failed to get challenge');
            }
    
            const { challengeData, message } = await challengeResponse.json();
    
            const signature = await signMessageAsync({
                message: message,
            });
    
            const authResponse = await fetch('/api/blast/solve', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    challengeData,
                    signature
                })
            });
    
            if (!authResponse.ok) {
                const errorData = await authResponse.json();
                throw new Error(errorData.message || 'Authentication failed');
            }
    
            const { bearerToken } = await authResponse.json();
            setBlastAuth(bearerToken);
            await fetchBlastBalance(bearerToken);
            await fetchBlastTransactions(bearerToken);
    
        } catch (err) {
            console.error('Blast authentication failed:', err);
            setError(err.message || 'Authentication failed. Please try again.');
        } finally {
            setLoading(false);
        }
    };
    
    const checkBatchStatus = async (batchId) => {
        if (!blastAuth || !contractAddress) {
            setError('Please enter Contract Address and check balance first');
            return;
        }
    
        try {
            const response = await fetch(
                `/api/blast/batch?contractAddress=${contractAddress}&batchId=${batchId}&token=${blastAuth}`
            );
    
            if (!response.ok) {
                throw new Error('Failed to fetch batch status');
            }
    
            const data = await response.json();
            setBatchStatus(data.batch);
            return data.batch;
        } catch (err) {
            console.error('Error checking batch status:', err);
            setError('Failed to check batch status');
        }
    };
    
    const cancelBatch = async (batchId) => {
        if (!blastAuth || !contractAddress) return;
    
        try {
            const response = await fetch(
                `${BLAST_POINTS_API}/contracts/${contractAddress}/batches/${batchId}`,
                {
                    method: 'DELETE',
                    headers: { 
                        'Authorization': `Bearer ${blastAuth}`,
                        'Content-Type': 'application/json',
                        'Origin': window.location.origin,
                        'Access-Control-Request-Method': 'DELETE',
                        'Access-Control-Request-Headers': 'Content-Type, Authorization'
                    },
                    credentials: 'include'
                }
            );
    
            if (!response.ok) {
                throw new Error('Failed to cancel batch');
            }
    
            await fetchBlastTransactions(blastAuth);
            setError('Batch cancelled successfully');
        } catch (err) {
            console.error('Error cancelling batch:', err);
            setError('Failed to cancel batch');
        }
    };
    
    
    const fetchBlastTransactions = async (token) => {
        if (!contractAddress || !token) return;
    
        try {
            const response = await fetch(
                `${BLAST_POINTS_API}/contracts/${contractAddress}/batches`,
                {
                    headers: { 
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                        'Origin': window.location.origin,
                        'Access-Control-Request-Method': 'GET',
                        'Access-Control-Request-Headers': 'Content-Type, Authorization'
                    },
                    credentials: 'include'
                }
            );
    
            if (!response.ok) {
                throw new Error('Failed to fetch transaction history');
            }
    
            const data = await response.json();
            setBlastTransactions(data.batches || []);
        } catch (err) {
            console.error('Error fetching transactions:', err);
        }
    };
    
    

    const updateStats = useCallback(async () => {
        if (!publicClient || !KONK_DROP_ADDRESS) return;
    
        try {
            const result = await publicClient.readContract({
                address: KONK_DROP_ADDRESS,
                abi: KONK_DROP_ABI,
                functionName: 'getStats'
            });
    
            setStats({
                totalDrops: result[0].toString(),
                totalTokensDropped: result[1].toString(),
                totalETHDropped: result[2].toString(),
                totalNFTsDropped: result[3].toString(),
                totalRecipients: result[4].toString()
            });
        } catch (error) {
            console.error('Error fetching stats:', error);
        }
    }, [publicClient]);
    
    useEffect(() => {
        if (isConnected) {
            updateStats();
            const interval = setInterval(updateStats, 30000); // Update every 30 seconds
            return () => clearInterval(interval);
        }
    }, [updateStats, isConnected]);

    // Fetch Blast balance
    const fetchBlastBalance = async (token) => {
        if (!contractAddress || !token) return;
        
        try {
            setRefreshingBalance(true);
            const response = await fetch(
                `/api/blast/balance?contractAddress=${contractAddress}&token=${token}`
            );
    
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Failed to fetch balance');
            }
    
            const data = await response.json();
            setBlastBalance(data.balancesByPointType);
        } catch (err) {
            console.error('Error fetching Blast balance:', err);
            setError('Failed to fetch Blast balance. Please try again.');
        } finally {
            setRefreshingBalance(false);
        }
    };


    // Handle Blast distribution
    const handleBlastDistribution = async () => {
        if (!blastAuth || !contractAddress) {
            setError('Please authenticate with Blast first');
            return;
        }
    
        try {
            setLoading(true);
            setError('');
    
            const transfers = walletInput
                .split('\n')
                .map(line => {
                    const [address, amount] = line.split(/[\s,]+/);
                    return { toAddress: address?.trim(), points: amount?.trim() };
                })
                .filter(({ toAddress, points }) => toAddress && points);
    
            try {
                validateBlastTransfers(transfers, pointType);
            } catch (validationError) {
                setError(validationError.message);
                return;
            }
    
            const response = await fetch(
                `/api/blast/batch?contractAddress=${contractAddress}&token=${blastAuth}`,
                {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        pointType,
                        transfers,
                        secondsToFinalize: BLAST_MIN_FINALIZE_SECONDS
                    })
                }
            );
    
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Failed to process distribution');
            }
    
            const result = await response.json();
            setCurrentBatchId(result.batchId);
            setShowBatchManagement(true);
            
            await checkBatchStatus(result.batchId);
            await Promise.all([
                fetchBlastTransactions(blastAuth),
                fetchBlastBalance(blastAuth)
            ]);
    
            setWalletInput('');
            setInputSummary({ totalAmount: '0', totalWallets: 0 });
    
        } catch (err) {
            console.error('Distribution error:', err);
            setError(err.message || 'Failed to distribute Blast Points/Gold');
        } finally {
            setLoading(false);
        }
    };

    const getEthersSigner = async () => {
        if (!window.ethereum) return null;
        const provider = new ethers.BrowserProvider(window.ethereum);
        return await provider.getSigner();
    };
  
const handleAirdrop = async () => {
    if (!isUserEligible) {
        setShowPopup(true);
        return;
    }

    if (mode === 'blast') {
        await handleBlastDistribution();
        return;
    }

    try {
        const entries = walletInput
            .split('\n')
            .map(line => line.trim())
            .filter(line => line)
            .map(line => {
                const [address, amount] = line.split(/[\s,]+/);
                return { address: address?.trim(), amount: amount?.trim() };
            })
            .filter(({ address, amount }) => address && amount);

        const addresses = entries.map(e => e.address);

        if (mode === 'eth') {
            setIsDistributing(true);
            try {
                const amounts = entries.map(e => parseEther(e.amount));
                const total = amounts.reduce((a, b) => a + b, 0n);

                const balance = await publicClient.getBalance({ address: walletAddress });
                if (balance < total) {
                    throw new Error(`Insufficient ETH balance. You need ${formatEther(total)} ETH`);
                }

                setNotification({
                    show: true,
                    message: 'Please confirm the transaction in your wallet',
                    hash: ''
                });

                const hash = await writeContractAsync({
                    address: KONK_DROP_ADDRESS,
                    abi: KONK_DROP_ABI,
                    functionName: 'disperseETH',
                    args: [addresses, amounts],
                    value: total
                });

                setTxHash(hash);
                
                setNotification({
                    show: true,
                    message: 'Transaction submitted...',
                    hash
                });

            } catch (err) {
                console.error('ETH Distribution error:', err);
                if (err.message.includes('User rejected')) {
                    setNotification({
                        show: true,
                        message: 'Transaction was rejected',
                        hash: ''
                    });
                } else {
                    setError(formatErrorMessage(err));
                    setNotification({
                        show: true,
                        message: formatErrorMessage(err),
                        hash: ''
                    });
                }
                setIsDistributing(false);
            }
        } 
        else if (mode === 'erc20') {
            const amounts = entries.map(e => parseEther(e.amount));
            const total = amounts.reduce((a, b) => a + b, 0n);

            const tokenBalance = await publicClient.readContract({
                address: tokenAddress,
                abi: ERC20_ABI,
                functionName: 'balanceOf',
                args: [walletAddress],
            });

            if (tokenBalance < total) {
                throw new Error(`Insufficient token balance. You need ${formatEther(total)} tokens`);
            }

            const allowance = await publicClient.readContract({
                address: tokenAddress,
                abi: ERC20_ABI,
                functionName: 'allowance',
                args: [walletAddress, KONK_DROP_ADDRESS],
            });

            if (allowance < total) {
                setIsApproving(true);
                try {
                    setNotification({
                        show: true,
                        message: 'Please approve token spending in your wallet',
                        hash: ''
                    });

                    const hash = await writeContractAsync({
                        address: tokenAddress,
                        abi: ERC20_ABI,
                        functionName: 'approve',
                        args: [KONK_DROP_ADDRESS, total]
                    });

                    setTxHash(hash);

                    setNotification({
                        show: true,
                        message: 'Approval submitted...',
                        hash
                    });

                } catch (err) {
                    console.error('Token approval error:', err);
                    if (err.message.includes('User rejected')) {
                        setNotification({
                            show: true,
                            message: 'Approval was rejected',
                            hash: ''
                        });
                    } else {
                        setError(formatErrorMessage(err));
                    }
                    setIsApproving(false);
                    return;
                }
                return;
            }

            setIsDistributing(true);
            try {
                setNotification({
                    show: true,
                    message: 'Please confirm the distribution in your wallet',
                    hash: ''
                });

                const hash = await writeContractAsync({
                    address: KONK_DROP_ADDRESS,
                    abi: KONK_DROP_ABI,
                    functionName: 'disperseERC20',
                    args: [tokenAddress, addresses, amounts]
                });

                setTxHash(hash);

                setNotification({
                    show: true,
                    message: 'Distribution submitted...',
                    hash
                });

            } catch (err) {
                console.error('Token distribution error:', err);
                if (err.message.includes('User rejected')) {
                    setNotification({
                        show: true,
                        message: 'Distribution was rejected',
                        hash: ''
                    });
                } else {
                    setError(formatErrorMessage(err));
                    setNotification({
                        show: true,
                        message: formatErrorMessage(err),
                        hash: ''
                    });
                }
                setIsDistributing(false);
            }
        }
        else if (mode === 'erc721') {
            const tokenIds = entries.map(e => Number(e.amount));

            const isApproved = await publicClient.readContract({
                address: tokenAddress,
                abi: ERC721_ABI,
                functionName: 'isApprovedForAll',
                args: [walletAddress, KONK_DROP_ADDRESS],
            });

            if (!isApproved) {
                setIsApproving(true);
                try {
                    setNotification({
                        show: true,
                        message: 'Please approve NFT spending in your wallet',
                        hash: ''
                    });

                    const hash = await writeContractAsync({
                        address: tokenAddress,
                        abi: ERC721_ABI,
                        functionName: 'setApprovalForAll',
                        args: [KONK_DROP_ADDRESS, true]
                    });

                    setTxHash(hash);

                    setNotification({
                        show: true,
                        message: 'Approval submitted...',
                        hash
                    });

                } catch (err) {
                    console.error('NFT approval error:', err);
                    if (err.message.includes('User rejected')) {
                        setNotification({
                            show: true,
                            message: 'Approval was rejected',
                            hash: ''
                        });
                    } else {
                        setError(formatErrorMessage(err));
                    }
                    setIsApproving(false);
                    return;
                }
                return;
            }

            setIsDistributing(true);
            try {
                setNotification({
                    show: true,
                    message: 'Please confirm the NFT distribution in your wallet',
                    hash: ''
                });

                const hash = await writeContractAsync({
                    address: KONK_DROP_ADDRESS,
                    abi: KONK_DROP_ABI,
                    functionName: 'disperseERC721',
                    args: [tokenAddress, addresses, tokenIds]
                });

                setTxHash(hash);

                setNotification({
                    show: true,
                    message: 'Distribution submitted...',
                    hash
                });

            } catch (err) {
                console.error('NFT distribution error:', err);
                if (err.message.includes('User rejected')) {
                    setNotification({
                        show: true,
                        message: 'Distribution was rejected',
                        hash: ''
                    });
                } else {
                    setError(formatErrorMessage(err));
                    setNotification({
                        show: true,
                        message: formatErrorMessage(err),
                        hash: ''
                    });
                }
                setIsDistributing(false);
            }
        }
    } catch (err) {
        console.error('Transaction error:', err);
        if (!err.message.includes('User rejected')) {
            setError(formatErrorMessage(err));
        }
        setIsApproving(false);
        setIsDistributing(false);
    }
};

useEffect(() => {
    if (!txHash) return;

    if (isPending) {
        setNotification({
            show: true,
            message: 'Transaction pending...',
            hash: txHash
        });
    }

    if (isSuccess && receipt) {
        // Handle success based on mode
        if (mode === 'eth') {
            const totalAmount = walletInput
                .split('\n')
                .map(line => line.trim())
                .filter(line => line)
                .map(line => {
                    const [, amount] = line.split(/[\s,]+/);
                    return parseFloat(amount?.trim() || '0');
                })
                .reduce((sum, amount) => sum + amount, 0);

            setNotification({
                show: true,
                message: `Successfully distributed ${totalAmount.toFixed(4)} ETH! 🚀`,
                hash: txHash
            });
        } else if (mode === 'erc20') {
            if (isApproving) {
                setNotification({
                    show: true,
                    message: 'Approval successful! Please confirm the distribution',
                    hash: txHash
                });
                setIsApproved(true);
                setIsApproving(false);
            } else {
                setNotification({
                    show: true,
                    message: 'Token distribution successful! 🚀',
                    hash: txHash
                });
            }
        } else if (mode === 'erc721') {
            if (isApproving) {
                setNotification({
                    show: true,
                    message: 'Approval successful! Please confirm the NFT distribution',
                    hash: txHash
                });
                setIsApproved(true);
                setIsApproving(false);
            } else {
                setNotification({
                    show: true,
                    message: 'NFT distribution successful! 🚀',
                    hash: txHash
                });
            }
        }

        if (!isApproving) {
            setTimeout(() => setNotification({ show: false, message: '', hash: '' }), 5000);
            setWalletInput('');
            setInputSummary({ totalAmount: '0', totalWallets: 0 });
            if (mode !== 'eth') setTokenAddress('');
        }

        setIsDistributing(false);
        setTxHash(null);
    }

    if (isError) {
        setError('Transaction failed');
        setNotification({
            show: true,
            message: 'Transaction failed',
            hash: txHash
        });
        setIsDistributing(false);
        setIsApproving(false);
        setTxHash(null);
    }
}, [txHash, isPending, isSuccess, isError, receipt, mode, isApproving]);


useEffect(() => {
    return () => {
        if (errorTimer) clearTimeout(errorTimer);
    };
}, [errorTimer]);

// Your existing file upload handler
const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    try {
        const text = await file.text();
        handleInputChange({ target: { value: text } });
    } catch (err) {
        setError('Error reading file');
    }
};

// Your existing input change handler
const handleInputChange = (e) => {
    const newInput = e.target.value;
    setWalletInput(newInput);
    
    try {
        const entries = newInput
            .split('\n')
            .map(line => line.trim())
            .filter(line => line)
            .map(line => {
                const [address, amount] = line.split(/[\s,]+/);
                return { address: address?.trim(), amount: amount?.trim() };
            })
            .filter(({ address, amount }) => address && amount);

        if (mode === 'erc721') {
            setInputSummary({
                totalAmount: entries.length.toString(),
                totalWallets: entries.length,
                isNFT: true
            });
        } else {
            const total = entries.reduce((sum, entry) => {
                try {
                    return sum + parseFloat(entry.amount);
                } catch (err) {
                    return sum;
                }
            }, 0);

            setInputSummary({
                totalAmount: total.toString(),
                totalWallets: entries.length,
                isNFT: false
            });
        }
    } catch (err) {
        setInputSummary({ totalAmount: '0', totalWallets: 0, isNFT: false });
    }
};

// Rich KONKS functions
const loadRichKonks = async () => {
    try {
        setLoading(true);
        setLoadingMessage('Fetching RICH KONKS addresses...');
        setRichKonksError('');
        
        const response = await fetch('/api/rich-konks');
        
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        
        const data = await response.json();
        
        if (data.error) {
            throw new Error(data.error);
        }

        if (data.fromCache) {
            setLoadingMessage('Using cached data from previous fetch');
        }
        
        const formattedInput = data.addresses
            .map(addr => `${addr} ${amountPerKonk}`)
            .join('\n');
        
        setWalletInput(formattedInput);
        setRichKonksCount(data.count || data.addresses.length);
        
        const total = data.addresses.length * Number(amountPerKonk);
        setInputSummary({
            totalAmount: total.toString(),
            totalWallets: data.addresses.length
        });

    } catch (err) {
        console.error('Failed to load RICH KONKS addresses:', err);
        setRichKonksError(err.message || 'Failed to load RICH KONKS addresses');
    } finally {
        setLoading(false);
        setLoadingMessage('');
    }
};

// Community holders functions
// const loadCommunityHolders = async () => {
//     try {
//         setLoading(true);
//         setLoadingMessage('Fetching community holders...');
//         setError('');

//         const baseUrl = window.location.origin;
//         const response = await fetch(
//             `${baseUrl}/api/community-holders?community=${selectedCommunity}&minHolding=${minHolding}`
//         );

//         if (!response.ok) {
//             throw new Error(`HTTP error! status: ${response.status}`);
//         }

//         const data = await response.json();

//         if (data.error) {
//             throw new Error(data.error);
//         }

//         setHoldersData(data);

//         let formattedInput;
//         if (distributionMode === 'equal') {
//             const amountPerHolder = Number(amountPerKonk) / data.holders.length;
//             formattedInput = data.holders
//                 .map(holder => `${holder.address} ${amountPerHolder}`)
//                 .join('\n');
//         } else {
//             const totalHoldings = data.holders.reduce(
//                 (sum, holder) => sum + parseUnits(holder.balance, 18),
//                 0n
//             );
            
//             formattedInput = data.holders
//                 .map(holder => {
//                     const proportion = Number(formatUnits(holder.balance, 18)) / Number(formatUnits(totalHoldings, 18));
//                     const amount = Number(amountPerKonk) * proportion;
//                     return `${holder.address} ${amount}`;
//                 })
//                 .join('\n');
//         }

//         setWalletInput(formattedInput);
//         setRichKonksCount(data.count);

//     } catch (err) {
//         console.error('Failed to load community holders:', err);
//         setError(err.message || 'Failed to load community holders');
//     } finally {
//         setLoading(false);
//         setLoadingMessage('');
//     }
// };

return (
    <div className="drop-container">
        <div className="header-section">
            <div className="header-content">
                <div className="header-left">
                    <Link to="/" className="home-icon">
                        <Home size={24} />
                    </Link>
                    <div className="logo-title">
                        <div className="header-text">
                            <h1>KONK DROP</h1>
                            <p>Airdrop your tokens with extra KONKness! 🚀</p>
                        </div>
                    </div>
                </div>
                <div className="header-right">
                    <ConnectButton />
                    <a 
                        href="https://kiyosonk.gitbook.io/konk-hypers-pool/smart-contract-details/contract-addresses" 
                        target="_blank" 
                        rel="noopener noreferrer"
                        className="docs-icon"
                    >
                        <FileText size={24} />
                    </a>
                </div>
            </div>
        </div>
 
        {isConnected ? (
            <>
                <div className="stats-row">
                    <div className="stat-box" data-tooltip="Total number of successful airdrops">
                        <span className="stat-value">{Number(stats.totalDrops).toLocaleString()}</span>
                        <span className="stat-label">Total Drops</span>
                    </div>
                    <div className="stat-box" data-tooltip="Total ETH distributed">
                        <span className="stat-value">
                            {Number(formatEther(stats.totalETHDropped || 0n)).toLocaleString(undefined, {
                                maximumFractionDigits: 4
                            })} ETH
                        </span>
                        <span className="stat-label">ETH Dropped</span>
                    </div>
                    <div className="stat-box" data-tooltip="Total tokens distributed">
                        <span className="stat-value">
                            {Number(formatEther(stats.totalTokensDropped || 0n)).toLocaleString(undefined, {
                                maximumFractionDigits: 2
                            })}
                        </span>
                        <span className="stat-label">Tokens Dropped</span>
                    </div>
                    <div className="stat-box" data-tooltip="Total NFTs distributed">
                        <span className="stat-value">{Number(stats.totalNFTsDropped || 0).toLocaleString()}</span>
                        <span className="stat-label">NFTs Dropped</span>
                    </div>
                    <div className="stat-box" data-tooltip="Total recipient addresses">
                        <span className="stat-value">{Number(stats.totalRecipients).toLocaleString()}</span>
                        <span className="stat-label">Recipients</span>
                    </div>
                </div>
 
                <div className="mode-tabs">
                    <button 
                        className={`tab ${mode === 'eth' ? 'active' : ''}`}
                        onClick={() => setMode('eth')}
                    >
                        ETH
                    </button>
                    <button 
                        className={`tab ${mode === 'erc20' ? 'active' : ''}`}
                        onClick={() => setMode('erc20')}
                    >
                        ERC20
                    </button>
                    <button 
                        className={`tab ${mode === 'erc721' ? 'active' : ''}`}
                        onClick={() => setMode('erc721')}
                    >
                        NFTs
                    </button>
                    <button 
                        className={`tab ${mode === 'blast' ? 'active' : ''}`}
                        onClick={() => setMode('blast')}
                    >
                        Blast Gold/Points ⚡
                    </button>
                </div>
 
                <div className="drop-box">
                    {mode === 'blast' && (
                        <>
                            <div className="section-auth">
                                <div className="community-info">
                                    <h3>Blast Gold/Points Distribution</h3>
                                    <p>Distribute Blast Gold or Points to multiple addresses</p>
                                </div>
 
                                <div className="operator-info">
                                    <p>
                                        <AlertCircle size={16} className="info-icon" />
                                        Connect with the wallet address configured as Points Operator to manage distributions
                                    </p>
                                </div>
 
                                <div className="distribution-mode">
                                    <label className="distribution-option">
                                        <input
                                            type="radio"
                                            name="pointType"
                                            value="PHASE2_GOLD"
                                            checked={pointType === 'PHASE2_GOLD'}
                                            onChange={(e) => setPointType(e.target.value)}
                                        />
                                        <span className="radio-custom"></span>
                                        <span>Blast Gold</span>
                                    </label>
                                    
                                    <label className="distribution-option">
                                        <input
                                            type="radio"
                                            name="pointType"
                                            value="PHASE2_POINTS"
                                            checked={pointType === 'PHASE2_POINTS'}
                                            onChange={(e) => setPointType(e.target.value)}
                                        />
                                        <span className="radio-custom"></span>
                                        <span>Blast Points</span>
                                    </label>
                                </div>
 
                                <div className="token-input-wrapper">
                                    <input
                                        type="text"
                                        placeholder="Contract Address (that has Blast Points/Gold)"
                                        value={contractAddress}
                                        onChange={(e) => setContractAddress(e.target.value)}
                                        className="token-input"
                                    />
                                    {blastBalance ? (
                                        <button 
                                            className={`refresh-button ${refreshingBalance ? 'refreshing' : ''}`}
                                            onClick={() => fetchBlastBalance(blastAuth)}
                                            disabled={refreshingBalance}
                                        >
                                            <RefreshCcw size={16} className="refresh-icon" />
                                        </button>
                                    ) : (
                                        <button 
                                            className="check-balance-btn"
                                            onClick={authenticateBlast}
                                            disabled={!contractAddress || loading}
                                        >
                                            Connect
                                        </button>
                                    )}
                                </div>
 
                                {error && <div className="error-message">{error}</div>}
 
                                {blastBalance && (
                                    <div className="blast-balance">
                                        <div className="balance-header">
                                            <h4>Available Balance</h4>
                                        </div>
                                        <div className="balance-amounts">
                                            <p>
                                                <span>Gold:</span> 
                                                {formatBlastAmount(blastBalance?.PHASE2_GOLD?.available, 'PHASE2_GOLD')}
                                            </p>
                                            <p>
                                                <span>Points:</span> 
                                                {formatBlastAmount(blastBalance?.PHASE2_POINTS?.available, 'PHASE2_POINTS')}
                                            </p>
                                        </div>
                                    </div>
                                )}
                            </div>

                            {currentBatchId && (
                                <div className="batch-management">
                                    <div className="batch-header">
                                        <h4>Current Batch Status</h4>
                                        <div className="batch-id">
                                            Batch ID: {currentBatchId}
                                            <button 
                                                className="copy-button"
                                                onClick={() => navigator.clipboard.writeText(currentBatchId)}
                                            >
                                                Copy
                                            </button>
                                        </div>
                                    </div>
                                    
                                    {batchStatus && (
                                        <div className="batch-details">
                                            <p>Status: <span className={`status-${batchStatus.status.toLowerCase()}`}>
                                                {batchStatus.status}
                                            </span></p>
                                            <p>Total Points: {formatBlastAmount(batchStatus.points, pointType)}</p>
                                            <p>Recipients: {batchStatus.transferCount}</p>
                                            <p>Created: {new Date(batchStatus.createdAt).toLocaleString()}</p>
                                            {batchStatus.finalizeAt && (
                                                <p>Finalizes: {new Date(batchStatus.finalizeAt).toLocaleString()}</p>
                                            )}
                                            
                                            <div className="batch-actions">
                                                <button 
                                                    className="refresh-button"
                                                    onClick={() => checkBatchStatus(currentBatchId)}
                                                >
                                                    <RefreshCcw size={16} /> Check Status
                                                </button>
                                                
                                                {batchStatus.status === 'PENDING' && (
                                                    <button 
                                                        className="cancel-button"
                                                        onClick={() => cancelBatch(currentBatchId)}
                                                    >
                                                        Cancel Batch
                                                    </button>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )}
 
                            {blastAuth && (
                                <>
                                    <div className="section-batch-management">
                                        <div className="batch-lookup-section">
                                            <div className="batch-lookup-header">
                                                <h4>Batch Lookup</h4>
                                            </div>
                                            <div className="batch-lookup-form">
                                                <input
                                                    type="text"
                                                    placeholder="Enter Batch ID to check status"
                                                    value={batchLookupId}
                                                    onChange={(e) => setBatchLookupId(e.target.value)}
                                                    className="batch-input"
                                                />
                                                <button 
                                                    className="lookup-button"
                                                    onClick={() => checkBatchStatus(batchLookupId)}
                                                    disabled={!batchLookupId || loading}
                                                >
                                                    Lookup Batch
                                                </button>
                                            </div>
 
                                            {batchStatus && batchStatus.id === batchLookupId && (
                                                <div className="batch-details">
                                                    <p>Status: <span className={`status-${batchStatus.status.toLowerCase()}`}>
                                                        {batchStatus.status}
                                                    </span></p>
                                                    <p>Total Points: {formatBlastAmount(batchStatus.points, batchStatus.pointType)}</p>
                                                    <p>Recipients: {batchStatus.transferCount}</p>
                                                    <p>Created: {new Date(batchStatus.createdAt).toLocaleString()}</p>
                                                    {batchStatus.finalizeAt && (
                                                        <p>Finalizes: {new Date(batchStatus.finalizeAt).toLocaleString()}</p>
                                                    )}
                                                    
                                                    <div className="batch-actions">
                                                        <button 
                                                            className="refresh-button"
                                                            onClick={() => checkBatchStatus(batchLookupId)}
                                                        >
                                                            <RefreshCcw size={16} /> Check Status
                                                        </button>
                                                        
                                                        {batchStatus.status === 'PENDING' && (
                                                            <button 
                                                                className="cancel-button"
                                                                onClick={() => cancelBatch(batchLookupId)}
                                                            >
                                                                Cancel Batch
                                                            </button>
                                                        )}
                                                    </div>
                                                </div>
                                            )}
                                        </div>
 
                                        {blastTransactions.length > 0 && (
                                            <div className="transaction-history">
                                                <div className="history-header">
                                                    <h4>Recent Transactions</h4>
                                                    <button onClick={() => setShowTransactionHistory(!showTransactionHistory)}>
                                                        {showTransactionHistory ? 'Hide' : 'Show'} History
                                                    </button>
                                                </div>
                                                
                                                {showTransactionHistory && (
                                                    <div className="history-list">
                                                        {blastTransactions.map((tx) => (
                                                            <div key={tx.id} className="history-item">
                                                                <div className="history-item-header">
                                                                    <span className="batch-id">#{tx.id}</span>
                                                                    <span className={`status-${tx.status.toLowerCase()}`}>
                                                                        {tx.status}
                                                                    </span>
                                                                </div>
                                                                <div className="history-details">
                                                                    <p>{tx.pointType}</p>
                                                                    <p>{formatBlastAmount(tx.points, tx.pointType)}</p>
                                                                    <p>{tx.transferCount} recipients</p>
                                                                    <p>{new Date(tx.createdAt).toLocaleString()}</p>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                )}
                                            </div>
                                        )}
 
                                        <div className="leaderboard-section">
                                            <div className="leaderboard-header">
                                                <h4>Blast Leaderboard Registration</h4>
                                                <button 
                                                    className="toggle-form-button"
                                                    onClick={() => setShowLeaderboardForm(!showLeaderboardForm)}
                                                >
                                                    {showLeaderboardForm ? 'Hide Form' : 'Register Project'}
                                                </button>
                                            </div>
                                            
                                            {showLeaderboardForm && (
                                                <div className="leaderboard-form">
                                                    <div className="form-info">
                                                        <AlertCircle size={16} />
                                                        <p>
                                                            <strong>Eligibility Criteria:</strong> To appear on the Blast dApp leaderboard, 
                                                            your contract must have either accumulated at least 100,000 points or received 
                                                            a Gold grant from Blast. Registration details can only be set once and cannot 
                                                            be modified later.
                                                        </p>
                                                    </div>
 
                                                    <div className="form-group">
                                                        <input
                                                            type="text"
                                                            placeholder="Project Name (3-20 characters)"
                                                            value={leaderboardInfo.name}
                                                            onChange={(e) => setLeaderboardInfo({
                                                                ...leaderboardInfo,
                                                                name: e.target.value
                                                            })}
                                                            className="leaderboard-input"
                                                        />
                                                    </div>
 
                                                    <div className="form-group">
                                                        <select
                                                            value={leaderboardInfo.category}
                                                            onChange={(e) => setLeaderboardInfo({
                                                                ...leaderboardInfo,
                                                                category: e.target.value
                                                            })}
                                                            className="leaderboard-select"
                                                        >
                                                            <option value="">Select Category</option>
                                                            {BLAST_CATEGORIES.map(category => (
                                                                <option key={category} value={category}>
                                                                    {category}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
 
                                                    <div className="form-group">
                                                        <input
                                                            type="text"
                                                            placeholder="Twitter Username (without @)"
                                                            value={leaderboardInfo.twitter}
                                                            onChange={(e) => setLeaderboardInfo({
                                                                ...leaderboardInfo,
                                                                twitter: e.target.value
                                                            })}
                                                            className="leaderboard-input"
                                                        />
                                                    </div>
 
                                                    <div className="form-group">
                                                        <input
                                                            type="text"
                                                            placeholder="Project Image URL (96x96 PNG)"
                                                            value={leaderboardInfo.imageUrl}
                                                            onChange={(e) => setLeaderboardInfo({
                                                                ...leaderboardInfo,
                                                                imageUrl: e.target.value
                                                            })}
                                                            className="leaderboard-input"
                                                        />
                                                    </div>
 
                                                    <button
                                                        className="register-button"
                                                        onClick={registerOnLeaderboard}
                                                        disabled={loading || !leaderboardInfo.name || !leaderboardInfo.category || 
                                                                 !leaderboardInfo.twitter || !leaderboardInfo.imageUrl}
                                                    >
                                                        {loading ? 'Registering...' : 'Register on Leaderboard'}
                                                    </button>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </>
                            )}
                        </>
                    )}
 
                    {(mode === 'erc20' || mode === 'erc721') && (
                    <input
                            type="text"
                            placeholder={`${mode.toUpperCase()} Token Address`}
                           value={tokenAddress}
                           onChange={(e) => setTokenAddress(e.target.value)}
                           className="token-input"
                       />
                   )}

                   <div className="input-section">
                       <div className="file-upload">
                           <input
                               type="file"
                               accept=".csv"
                               onChange={handleFileUpload}
                               id="file-upload"
                           />
                           <label htmlFor="file-upload">
                               <Upload className="upload-icon" />
                               <span>Upload CSV</span>
                           </label>
                       </div>

                       <textarea
                           value={walletInput}
                           onChange={handleInputChange}
                           placeholder={`Enter addresses and ${
                               mode === 'blast' 
                                   ? `${pointType === 'PHASE2_GOLD' ? 'Gold' : 'Points'} amounts` 
                                   : mode === 'erc721' 
                                   ? "NFT's Token ID" 
                                   : 'amounts'
                           } (one per line):${
                               mode === 'erc721' 
                                   ? '\n0x123...abc 351' 
                                   : '\n0x123...abc 1.5\n0x456...def 2.0'
                           }`}
                           className="wallet-input"
                       />

                       {inputSummary.totalWallets > 0 && (
                           <div className="input-summary">
                               <p>Total Recipients: {inputSummary.totalWallets}</p>
                               <p>Total Amount: {inputSummary.totalAmount} {
                                   mode === 'eth' 
                                       ? 'ETH' 
                                       : mode === 'blast' 
                                       ? pointType === 'PHASE2_GOLD' ? 'Gold' : 'Points'
                                       : mode === 'erc721'
                                       ? 'NFTs'
                                       : 'Tokens'
                               }</p>
                           </div>
                       )}
                   </div>

                   {error && (
                        <div className="error-message">
                            <span>{error}</span>
                            <button onClick={() => setError('')}>×</button>
                        </div>
                    )}

                   <button 
                        className={`airdrop-button ${(isApproving || isDistributing) ? 'loading' : ''} ${isApproved ? 'approved' : ''}`}
                        onClick={handleAirdrop}
                        disabled={loading || !walletInput.trim() || (mode === 'blast' && !blastAuth) || isApproving || isDistributing}
                    >
                        {isApproving ? (
                            <>
                                <span className="spinner"></span>
                                Approving...
                            </>
                        ) : isDistributing ? (
                            <>
                                <span className="spinner"></span>
                                Distributing...
                            </>
                        ) : isApproved ? (
                            'Distribute Now'
                        ) : mode === 'blast' ? (
                            `Distribute ${pointType === 'PHASE2_GOLD' ? 'Gold' : 'Points'}`
                        ) : mode === 'eth' ? (
                            'Distribute ETH'
                        ) : mode === 'erc721' ? (
                            isApproved ? 'Distribute NFTs' : 'Approve NFTs'
                        ) : (
                            isApproved ? 'Distribute Tokens' : 'Approve Tokens'
                        )}
                    </button>
               </div>
           </>
       ) : (
           <div className="connect-wallet-prompt">
               <h2>Connect Your Wallet</h2>
               <p>Connect your wallet to start dropping tokens like it's hot! 🔥</p>
               <div className="connect-button-wrapper">
                   <ConnectButton />
               </div>
           </div>
       )}
        
       {showPopup && <EligibilityPopup onClose={() => setShowPopup(false)} />}
       <div className="footer">
            <img 
                src="/images/blastlogo.svg" 
                alt="Blast Logo"
                className="blast-logo"
                width="128"
                height="128"
            />
        </div>
        
        <TransactionNotification 
            show={notification.show}
            message={notification.message}
            hash={notification.hash}
            onClose={() => setNotification({ show: false, message: '', hash: '' })}
        />
    </div>
);
};

export default KONKDrop;